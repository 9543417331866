<template>
  <section class="Project">
    <!-- 页面 header 通屏大图 -->
    <FullBanner :bannerURL="$route.meta.img" moduleName="Program">
      <template v-slot:customFill>
        <!-- <iq-card class="iq-card"> -->
        <section class="container" style="display: flex; justify-content: flex-end; padding-right: 35px">
          <!-- <el-row class="py-3">
              <el-col :span="24">
                <el-input
                  clearable
                  @clear="getAwardList"
                  @keyup.enter.native="getAwardList"
                  class="w-100"
                  placeholder="Search Program"
                  v-model="search"
                ></el-input>
              </el-col>
            </el-row> -->
          <div @click="$router.push('/award-certificate')" class="toMyCourse">
            <img style="height: 20px" src="../../assets/images/course/certificate.png" alt="" />
            <span style="color: #50b5ff">My Certificate</span>
          </div>
        </section>
        <!-- </iq-card> -->
      </template>
    </FullBanner>
    <div>
      <el-row v-if="loading" class="container">
        <el-col class="cardContent p-1" :xs="24" :sm="12" :md="8" v-for="(item, index) in 9" :key="index">
          <el-skeleton animated>
            <template slot="template">
              <iq-card body-class="p-0" style="border-radius: 5px;overflow: hidden;">
                <el-skeleton-item variant="image" style="width: 100%; height: 160px;" />
                <div style="padding: 38px 10px 22px;height: 100px;">
                  <el-skeleton-item variant="text" style="width: 160px;height: 25px;" />
                </div>
              </iq-card>
            </template>
          </el-skeleton>
        </el-col>
      </el-row>
      <div v-if="awardList.length&&!loading" class="container">
        <Prerecording :awardList="awardList" />
      </div>
      <section v-else>
        <div class="d-flex justify-content-center" style="background:#fff;margin-top: 60px" v-if="!loading">
          <img style="width:250px;" src="../../assets/images/group/empty1.png" alt="" />
        </div>
      </section>
    </div>
    <!-- <el-row v-if="awardList.length" class="container">
      <el-col
        class="cardContent"
        :span="8"
        v-for="item in awardList"
        :key="item.id"
      >
        <Prerecording
          @click.native="$router.push(`/second-level?id=${item.id}&type=2`)"
          :cardInfo="item"
        />
      </el-col>
    </el-row> -->
    <!-- <div v-if="awardList.length" class="d-flex justify-content-end container">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="12"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </section>
</template>
<script>
  import FullBanner from "@/components/ComComponents/FullBanner";
  import CountrySelection from "@/components/ComComponents/CountrySelection";
  import Prerecording from "./Components/Prerecording";
  import Pagination from "@/components/ComComponents/Pagination";
  import OrganazationSel from "@/components/ComComponents/OrganazationSel";
  export default {
    components: {
      FullBanner,
      CountrySelection,
      Prerecording,
      Pagination,
      OrganazationSel,
    },
    data() {
      return {
        active: "explore",
        awardList: [],
        type: 0,
        search: "",

        page: 1,
        total: 0,
        loading: true,
      };
    },
    created() {
      this.getAwardList();
    },

    methods: {
      handleCurrentChange(page) {
        this.page = page;
        this.getAwardList();
      },

      // 获取项目列表
      getAwardList() {
        this.loading = true
        this.$http
          .getProgramCategoryList({
            keyword: this.search,
            // per_page: 12,
            // type: this.type,
            // page: this.page,
          })
          .then((res) => {
            if (res.status == 200) {
              // this.total = res.data.total;
              this.awardList = res.data;
              setTimeout(() => {
                this.loading = false
              }, 0);
            }
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .Project {
    min-height: 100vh;

    .title {
      margin-bottom: 18px;
    }

    .profile-info {
      min-height: 130px;
    }

    .searchBox {
      // padding: 0 8px;
      position: relative;

      img {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-date-editor--date .el-input__inner,
  ::v-deep .el-select .el-input__inner,
  ::v-deep .el-input__inner {
    height: 45px;
    border-radius: 11px;
  }

  .toMyCourse {
    height: 35px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }
</style>