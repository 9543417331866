<template>
  <div class="course-card">
    <el-row>
      <el-col class="cardContent p-1 px-2"  :xs="24" :sm="12" :md="8" v-for="(item, index) in awardList" :key="index">
        <router-link :to="{ path: `/second-level?id=${item.id}&type=2` }">
          <iq-card body-class="profile-page p-0" class="iqCard">
            <template v-slot:body>
              <div class="profile-header-image h-100">
                <div style="height: 160px; overflow: hidden" class="cover-container">
                  <img :src="item.cover" alt="profile-bg" class="backImg img-fluid h-100 w-100" />
                </div>
                <div class="userDetail p-2">
                  <div class="introduce">
                    <h4 class="title">{{ item.name }}</h4>
                  </div>
                </div>
              </div>
            </template>
          </iq-card>
        </router-link>
      </el-col>
    </el-row>
    <div class="d-flex justify-content-end"></div>
  </div>
  <!-- <iq-card body-class="p-0" class="iqCard">
    <div class="image_box">
      <el-image
        style="
          width: 100%;
          height: 120px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        "
        fit="cover"
        :src="cardInfo.cover"
      >
      </el-image>
    </div>
    <div class="userDetail">
      <div class="title-date">
        <h4 class="title">{{ cardInfo.name }}</h4>
      </div>
    </div>
  </iq-card> -->
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: {
      awardList: {
        type: Array,
      },
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  .course-card {
    .iqCard {
      box-shadow: 0px 0px 20px 0px rgba(75, 75, 75, 0.40) !important;

      &:hover {
        box-shadow: 9.19px 9.19px 27.16px 0.39px rgba(75, 75, 75, 0.45) !important;
      }
    }
  }

  .introduce {
    height: 95px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .backImg {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }
</style>