<template>
  <!-- parentClassName -->
  <section :class="parentClassName">
    <!-- childClassName -->
    <b-form class="formContent">
      <div :class="childClassName">
        <!-- grandsonClassName -->
        <div :class="grandsonClassName">
          <b-form-group :label="CountryLabel">
            <select
              class="form-control"
              v-model="params.countryid"
              @change="handleChangeSelect('parent')"
            >
              <option value="">Country</option>
              <option v-for="v in countryList" :key="v.id" :value="v.id">
                {{ v | addressName }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div :class="grandsonClassName" v-if="!isShow">
          <b-form-group :label="RegionLabel">
            <select
              class="form-control"
              :disabled="isDisable"
              v-model="params.regionid"
              @change="handleChangeSelect('child')"
            >
              <option value="">Region</option>
              <option v-for="v in regionList" :key="v.id" :value="v.id">
                {{ v | addressName }}
              </option>
            </select>
          </b-form-group>
        </div>
        <slot></slot>
      </div>
    </b-form>
  </section>
</template>
<script>
export default {
  name: 'CountrySelection',

  props: {
    parentClassName: {
      type: Array,
      default: () => {
        return ['CountrySelection']
      }
    },
    childClassName: {
      type: Array,
      default: () => {
        return ['row']
      }
    },
    grandsonClassName: {
      type: Array,
      default: () => {
        return ['col-md-2']
      }
    },
    RegionLabel: {
      type: String,
      default: ''
    },
    CountryLabel: {
      type: String,
      default: ''
    },
    groupDetail: {
      type: Object
    }
  },

  data () {
    return {
      isShow: false,
      countryList: [],
      regionList: [],
      params: {
        countryid: '',
        regionid: ''
      }
    }
  },

  computed: {
    // 是否禁用地区选择
    isDisable () {
      return this.params.countryid == ''
    }
  },

  filters: {
    // 筛选地区名字
    addressName (val) {
      return val['name']
    }
  },
  created () {
    this.getAddress()
  },
  methods: {
    /**
       * Fn         =>   获取国家/地区
       * parent_id  =>   0(国家) 其它(地区)
       * return     =>   Array
       */
    async getAddress (parent_id, level) {
      const params = {
        parent_id
      }
      let { status, data } = await this.$http.getAddress(params)
      if (status == 200) {
        if (!parent_id) {
          this.countryList = data
          // console.log(111);
          this.isShow = false
        } else {
          // console.log(222);
          this.regionList = data
          if (!this.regionList.length) {
            this.isShow = true
          } else {
            // let list = JSON.parse(JSON.stringify(data));
            // let item = list.shift();
            // this.params.regionid = item.id;
            this.isShow = false
          }
        }
        this.$emit('returnPrams', this.params)
      }
    },
    // 选中国家/地区
    handleChangeSelect (type) {
      if (type === 'parent') {
        this.$set(this.params, 'regionid', '')
        this.getAddress(this.params.countryid)
      } else {
        this.$emit('returnPrams', this.params)
      }
    }
  },

  mounted () {
    this.getAddress()
  },

  watch: {
    groupDetail (newVal, oldVal) {
      this.params.countryid = newVal.country_id || ''
      this.params.regionid = newVal.region_id || ''
      // this.getAddress()
      this.getAddress(this.params.countryid)
    },
    deep: true
  }
}
</script>
<style src='' lang='scss' scoped>
</style>
